<script>
import FullCalendar from "@fullcalendar/vue3";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import bootstrapPlugin from "@fullcalendar/bootstrap";
import listPlugin from "@fullcalendar/list";

import { required, helpers } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";

import Swal from "sweetalert2";

import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";

import { calendarEvents, categories } from "./data-calendar";
import { Erreur } from "../../../helpers/error";
import loaderProcess from "../../../components/widgets/loaderProcess.vue";
import { Api } from "../../../helpers";
/**
 * Calendar component
 */
export default {
  setup() {
    return { v$: useVuelidate() };
  },
  page: {
    title: "Calendar",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: {
    FullCalendar,
    Layout,
    PageHeader,
    loaderProcess,
  },
  data() {
    return {
      titre: "",
      choiceModal: false,
      joursSemaine: [],
      planificationList: [],
      planningId: "",
      onePlanning: "",
      updatePlannif: {
        programmeName: "",
        channelId: "",
        description: "",
        startTime: "",
        startDate: "",
        idProgramme: "",
      },
      timestamp: null,
      plannif: "onePlaning",
      idProgramme: "",
      description: "",
      startDate: "",
      endDate: "",
      startTime: "",
      endTime: "",
      time: null,
      channels: [],
      channelId: "",
      duree: null,
      modalOk: false,
      cascadeModalOk: false,
      // start: new Date().setDate(new Date().getDate() + 5),
      programmes: [],
      process: false,
      planification: [],
      title: "Planification",
      items: [
        {
          text: "Planification",
          // href: "/calendar"
        },
        {
          text: "Planifications",
          active: true,
        },
      ],
      calendarEvents: calendarEvents,
      /*calendarEvents: [ {
        id: 1,
        title: 'Hey!',
        start: new Date(),
        className: 'bg-warning text-white',
      }],*/
      calendarOptions: {
        headerToolbar: {
          left: "prev,next today",
          center: "title",
          right: "dayGridMonth,timeGridWeek,timeGridDay,listWeek",
        },
        locale: "fr",
        buttontText: {
          today: "Aujourd'hui",
        },
        plugins: [
          dayGridPlugin,
          timeGridPlugin,
          interactionPlugin,
          bootstrapPlugin,
          listPlugin,
        ],
        initialView: "dayGridMonth",
        themeSystem: "bootstrap",
        // initialEvents: calendarEvents,
        newCalendarApi: null,
        editable: true,
        droppable: true,
        eventResizableFromStart: true,
        // dateClick: this.dateClicked,
        eventClick: this.editEvent,
        eventsSet: this.handleEvents,
        weekends: true,
        selectable: true,
        selectMirror: true,
        dayMaxEvents: true,
      },
      currentEvents: [],
      showModal: false,
      eventModal: false,
      categories: categories,
      submitted: false,
      submit: false,
      newEventData: {},
      edit: {},
      deleteId: {},
      event: {
        title: "",
        category: "",
      },
      editevent: {
        editTitle: "",
        editcategory: "",
      },
    };
  },
  validations: {
    event: {
      title: {
        required: helpers.withMessage("Title is required", required),
      },
      category: {
        required: helpers.withMessage("Category is required", required),
      },
    },
  },

  /* mounted(){
    this.initialEvents = this.calendarEvents
    
  },*/

  methods: {
    /**
     * Modal form submit
     */
    // eslint-disable-next-line no-unused-vars
    handleSubmit(e) {
      this.submitted = true;

      // stop here if form is invalid
      this.v$.$touch();
      if (this.v$.$invalid) {
        return;
      } else {
        const title = this.event.title;
        const category = this.event.category;
        let calendarApi = this.newEventData.view.calendar;

        this.currentEvents = calendarApi.addEvent({
          id: this.newEventData.length + 1,
          title,
          start: this.newEventData.date,
          end: this.newEventData.date,
          classNames: [category],
        });
        this.successmsg();
        this.showModal = false;
        this.newEventData = {};
      }
      this.submitted = false;
      this.event = {};
    },
    // eslint-disable-next-line no-unused-vars
    hideModal(e) {
      this.submitted = false;
      this.showModal = false;
      this.event = {};
    },
    /**
     * Edit event modal submit
     */
    // eslint-disable-next-line no-unused-vars
    editSubmit() {
      this.choiceModal = false;
      this.eventModal = true;
      // const editTitle = this.editevent.editTitle;
      // const editcategory = this.editevent.editcategory;

      // this.edit.setProp("title", editTitle);
      // this.edit.setProp("classNames", editcategory);
      // this.successmsg();
      // this.eventModal = false;
    },

    /**
     * Delete event
     */
    deleteEvent() {
      Api.delete(`/streamvod/rest/planification/delete/${this.planningId}`)
        .then((response) => {
          console.log(response.data.content);
        })
        .catch((error) => {
          this.process = false;
          console.log(error);
          Erreur.gestionErreur(error.message);
        });
    },

    modalShow() {
      this.modalOk = true;
    },
    /**
     * Modal open for add event
     */
    dateClicked(info) {
      this.newEventData = info;
      this.showModal = true;
    },
    getDateAndTimeFromDate(date) {
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let day = date.getDate();
      let hours = date.getHours();
      let minutes = date.getMinutes();
      let seconds = date.getSeconds();

      // ajouter un zéro devant les chiffres de temps si nécessaire
      hours = hours < 10 ? "0" + hours : hours;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      seconds = seconds < 10 ? "0" + seconds : seconds;

      const dateStr = `${year}-${month}-${day}`;
      const timeStr = `${hours}:${minutes}:${seconds}`;

      return { date: dateStr, time: timeStr };
    },
    /**
     * Modal open for edit event
     */
    editEvent(info) {
      this.edit = info.event;
      console.log(this.edit);
      this.editevent.editTitle = this.edit.title;
      this.editevent.editcategory = this.edit.classNames[0];

      // this.howToDo()

      this.planification.forEach((items) => {
        if (items.programme.nom === this.edit.title) {
          this.planningId = items.id;
          console.log(items.id);
        }
      });

      this.process = true;

      Api.get(`/streamvod/rest/planification/${this.planningId}`)
        .then((response) => {
          this.process = false;
          this.choiceModal = true;
          this.onePlanning = response.data.content;
          console.log(this.onePlanning);
          this.updatePlannif.idProgramme = this.onePlanning.programme.id;
          this.updatePlannif.programmeName = this.onePlanning.programme.nom;

          this.getDuree(this.updatePlannif.idProgramme);

          this.onePlanning.channels.forEach((items) => {
            this.updatePlannif.channelId = items.id;
          });
          console.log(new Date(this.onePlanning.startDate));
          const { date, time } = this.getDateAndTimeFromDate(
            new Date(this.onePlanning.startDate)
          );
          console.log("date" + date, "heure" + time);
          this.updatePlannif.startDate = date;
          this.updatePlannif.startTime = time;

          this.updatePlannif.description = this.onePlanning.description;
        })
        .catch((error) => {
          this.process = false;
          console.log(error);
          Erreur.gestionErreur(error.message);
        });
    },

    closeModal() {
      this.eventModal = false;
    },

    confirm() {
      Swal.fire({
        title: "Etes vous sùre?",
        // text: "You won't be able to delete this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        cancelButtonText: "Annuler",
        confirmButtonText: "Supprimer",
      }).then((result) => {
        if (result.value) {
          this.deleteEvent();
          Swal.fire("Deleted!", "Event has been deleted.", "success");
        }
      });
    },

    howToDo() {
      Swal.fire({
        title: "Que voulez vous faire ?",
        // text: "You won't be able to delete this!",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Modifier",
        cancelButtonText: "Supprimer",
      }).then((result) => {
        if (result.value) {
          this.deleteEvent();
          Swal.fire("Deleted!", "Event has been deleted.", "success");
        }
      });
    },

    /**
     * Show list of events
     */
    handleEvents(events) {
      this.currentEvents = events;
    },

    /**
     * Show successfull Save Dialog
     */
    successmsg() {
      Swal.fire({
        position: "center",
        icon: "success",
        title: "Event has been saved",
        showConfirmButton: false,
        timer: 1000,
      });
    },

    getProgrammes() {
      Api.get("/streamvod/rest/programme/all")
        .then((response) => {
          this.programmes = response.data.content;
          console.log(this.programmes);
        })
        .catch((error) => {
          this.process = false;
          console.log(error);
          Erreur.gestionErreur(error.message);
        });
    },

    getChannel() {
      Api.get("/streamvod/api/channel/channel-all")
        .then((response) => {
          this.channels = response.data.content;
          console.log(this.channels);
        })
        .catch((error) => {
          this.process = false;
          console.log(error);
          Erreur.gestionErreur(error.message);
        });
    },
    getDuree(idProgramme) {
      Api.get("/streamvod/rest/programme/" + idProgramme)
        .then((response) => {
          this.duree = response.data.content.duree;
          console.log(this.duree);
        })
        .catch((error) => {
          this.process = false;
          console.log(error);
          Erreur.gestionErreur(error.message);
        });
    },

    createPlanning() {
      this.process = true;
      this.modalOk = false;

      const start = new Date(this.startDate + " " + this.startTime + ":00");
      const duration = this.duree.split(":").map(Number);
      const end = new Date(
        start.getTime() + duration[0] * 60 * 60 * 1000 + duration[1] * 60 * 1000
      );

      console.log(start, end);
      Api.postFormData(
        "/streamvod/rest/planification/" +
          this.idProgramme +
          "?channel=" +
          this.channelId,
        {
          startDate: Date.parse(start),
          endDate: Date.parse(end),
          description: this.description,
          channel: this.channelId,
        }
      )
        .then((response) => {
          this.process = false;
          console.log(response);
          Swal.fire(
            "Succes!",
            "Votre planification a été pris en compte!",
            "success"
          );
          location.reload();
        })
        .catch((error) => {
          this.process = false;
          console.log(error);
          Erreur.gestionErreur(error.message);
        });
    },

    updatePlanning() {
      this.process = true;
      this.modalOk = false;
      this.eventModal = false;

      const start = new Date(
        this.updatePlannif.startDate +
          " " +
          this.updatePlannif.startTime +
          ":00"
      );
      const duration = this.duree.split(":").map(Number);
      const end = new Date(
        start.getTime() + duration[0] * 60 * 60 * 1000 + duration[1] * 60 * 1000
      );

      console.log(start, end);

      Api.putFormData(
        `/streamvod/rest/planification/update/${this.planningId}?channel=${
          this.updatePlannif.channelId
        }&description=${this.updatePlannif.description}&endDate=${Date.parse(
          end
        )}&programmeId=${this.updatePlannif.idProgramme}&startDate=${Date.parse(
          start
        )}`
      )
        .then((response) => {
          this.process = false;
          console.log(response);
          Swal.fire(
            "Succes!",
            "Votre planification a été pris en compte!",
            "success"
          );
          location.reload();
        })
        .catch((error) => {
          this.process = false;
          console.log(error);
          Erreur.gestionErreur(error.message);
        });
    },

    addDataCalendar(item) {
      this.currentEvents = this.newCalendarApi.addEvent({
        id: 2,
        title: item.programme.nom,
        start: new Date(item.startDate),
        end: new Date(item.endDate),
        className: "c2play-primary",
      });
    },

    getPlanification() {
      this.initialEvents = [
        {
          id: 1,
          title: "Hey!",
          start: new Date(),
          className: "bg-warning text-white",
        },
      ];

      this.newCalendarApi = this.$refs.fullCalendar.getApi();

      //let calendarApi = this.$refs.fullCalendar.getApi() //this.newEventData.view.calendar

      console.log(this.$refs.fullCalendar.getApi());

      Api.get("/streamvod/rest/planification/all")
        .then((response) => {
          this.planificationList = response.data.content;
          console.log(this.planificationList);

          this.planificationList.forEach((item) => {
            console.log(item);
            this.currentEvents = this.newCalendarApi.addEvent({
              id: 2,
              title: item.programme.nom,
              start: new Date(item.startDate),
              end: new Date(item.endDate),
              className: "c2play-primary",
            });

            // item.channels.forEach((channel) => {
            //   if(channel.channel_name == "Canal 2 International"){
            //   this.addDataCalendar(item,"c2play-primary");
            // }
            // if(channel.channel_name == "Canal 2 Movies"){
            //   this.addDataCalendar(item,"c2play-movies-primary");
            // }
            // if(channel.channel_name == "Canal 2 Musique"){
            //   this.addDataCalendar(item,"c2play-musique-primary");
            // }
            // })
          });
        })
        .catch((error) => {
          this.process = false;
          console.log(error);
          Erreur.gestionErreur(error.message);
        });
    },

    afficherModal() {
      this.cascadeModalOk = true;
    },

    getDatesForWeekdays(startDate, endDate, weekdays) {
      const plageDates = [];
      const joursSemaine = [
        "Dimanche",
        "Lundi",
        "Mardi",
        "Mercredi",
        "Jeudi",
        "Vendredi",
        "Samedi",
      ];

      for (let i = 0; i < weekdays.length; i++) {
        const jour = weekdays[i];
        const jourIndex = joursSemaine.indexOf(jour);
        const dateJour = new Date(
          startDate.getTime() +
            ((jourIndex - startDate.getDay() + 7) % 7) * 86400000
        );

        while (dateJour <= endDate) {
          plageDates.push(new Date(dateJour));
          dateJour.setDate(dateJour.getDate() + 7);
        }
      }

      return plageDates;
    },
    cascadeRequest() {
      // this.process = true
      this.cascadeModalOk = false;

      const startDate = new Date(this.startDate + " " + this.startTime + ":00");
      const endDate = new Date(this.endDate + " " + this.startTime + ":00");
      const weekdays = this.joursSemaine;

      let plageDates = this.getDatesForWeekdays(startDate, endDate, weekdays);
      plageDates.forEach((items) => {
        const duration = this.duree.split(":").map(Number);
        const fin = new Date(
          items.getTime() +
            duration[0] * 60 * 60 * 1000 +
            duration[1] * 60 * 1000
        );
        console.log(items, fin);

        Api.postFormData(
          "/streamvod/rest/planification/" +
            this.idProgramme +
            "?channel=" +
            this.channelId,
          {
            startDate: Date.parse(items),
            endDate: Date.parse(fin),
            description: this.description,
            channel: this.channelId,
          }
        )
          .then((response) => {
            console.log(response);
            Swal.fire(
              "Succes!",
              "Votre planification a été pris en compte!",
              "success"
            );
          })
          .catch((error) => {
            this.process = false;
            console.log(error);
            Erreur.gestionErreur(error.message);
          });
      });

      // this.process = false
      //     location.reload()
    },
  },
  mounted() {
    this.getProgrammes();
    this.getPlanification();
    this.getChannel();
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <loader-process :visible="process" :progress="progress" :title="titre" />
    <div class="row justify-content-end">
      <div class="col-lg-10" style="margin-top: 10px">
        <button
          type="submit"
          class="btn c2play-primary"
          style="position: relative; left: 90%"
          @click="modalShow"
        >
          {{ $t("addButton") }}
        </button>
      </div>
    </div>
    <br />
    <div class="row justify-content-end">
      <div class="col-lg-10" style="margin-top: 10px">
        <button
          type="submit"
          class="btn c2play-primary"
          style="position: relative; left: 79%"
          @click="afficherModal"
        >
          Planification en cascade
        </button>
      </div>
    </div>
    <br />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="app-calendar">
              <FullCalendar
                ref="fullCalendar"
                :options="calendarOptions"
                @select="handleSelect"
              ></FullCalendar>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Edit Modal -->
    <b-modal
      v-model="eventModal"
      size="xl"
      :title="$t('pages.calandar.formulaire.updateTitle')"
      title-class="text-black font-18"
      hide-footer
      body-class="p-3"
    >
      <div class="card-body" v-if="plannif == 'onePlaning'">
        <form>
          <div class="row">
            <div class="col-lg-6">
              <label class="col-form-label">{{
                $t("pages.calandar.formulaire.channel")
              }}</label>
              <div class="col-md-12">
                <select class="form-control" v-model="updatePlannif.channelId">
                  <option
                    :value="channel.id"
                    v-for="channel in channels"
                    :key="channel.id"
                  >
                    {{ channel.channel_name }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-lg-6">
              <label class="col-form-label">{{
                $t("pages.calandar.formulaire.programme")
              }}</label>
              <div class="col-md-12">
                <select
                  class="form-control"
                  v-model="updatePlannif.idProgramme"
                  @change="getDuree(updatePlannif.idProgramme)"
                >
                  <option
                    :value="programme.id"
                    v-for="programme in programmes"
                    :key="programme.id"
                  >
                    {{ programme.nom }}
                  </option>
                </select>
              </div>
            </div>
          </div>

          <div class="row mb3">
            <div class="col-lg-6">
              <div class="form-group">
                <label for="projectname" class="col-form-label">{{
                  $t("pages.calandar.formulaire.date")
                }}</label>
                <div class="col-lg-12">
                  <input
                    id="projectname"
                    name="projectname"
                    type="date"
                    class="form-control"
                    v-model="updatePlannif.startDate"
                  />
                  {{ updatePlannif.startDate }}
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="form-group">
                <label for="projectname" class="col-form-label">{{
                  $t("pages.calandar.formulaire.time")
                }}</label>
                <div class="col-lg-12">
                  <input
                    id="projectname"
                    name="projectname"
                    type="time"
                    class="form-control"
                    v-model="updatePlannif.startTime"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="form-group row mb-3">
            <label for="projectdesc" class="col-form-label">{{
              $t("pages.calandar.formulaire.description.label")
            }}</label>
            <div class="col-lg-12">
              <textarea
                id="projectdesc"
                class="form-control"
                rows="3"
                :placeholder="
                  $t('pages.calandar.formulaire.description.placeholder')
                "
                v-model="updatePlannif.description"
              ></textarea>
            </div>
          </div>
        </form>
        <div class="row justify-content-end">
          <div class="col-lg-10">
            <button
              type="submit"
              class="btn c2play-primary"
              @click="updatePlanning"
            >
              {{ $t("updateButton") }}
            </button>
          </div>
        </div>
      </div>
    </b-modal>

    <b-modal
      v-model="choiceModal"
      title="Que voulez Faire"
      title-class="text-black font-18"
      hide-footer
      body-class="p-3"
    >
      <div class="text-end p-3">
        <div style="text-align: center">
          <h2>{{ updatePlannif.programmeName }}</h2>
          <h5>{{ updatePlannif.startDate }} {{ updatePlannif.startTime }}</h5>
        </div>
        <br />
        <div style="margin-right: 30%">
          <b-button class="ms-1" variant="success" @click="editSubmit">{{
            $t("pages.calandar.actions.modifier")
          }}</b-button>
          <b-button class="ms-1" variant="danger" @click="confirm">{{
            $t("pages.calandar.actions.supprimer")
          }}</b-button>
        </div>
      </div>
    </b-modal>

    <b-modal
      v-model="modalOk"
      id="modal-xl"
      size="xl"
      :title="$t('pages.calandar.formulaire.title')"
      title-class="font-18"
      hide-footer
    >
      <!-- <div class="card" style="padding: 25px; width: 70%; margin-left: auto; margin-right: auto; border-radius: 15px;"> -->

      <!-- <div class="card" style="padding: 25px; width: 65%; margin-left: auto; margin-right: auto; border-radius: 15px;"> -->
      <div class="card-body" v-if="plannif == 'onePlaning'">
        <form>
          <div class="row">
            <div class="col-lg-6">
              <label class="col-form-label">{{
                $t("pages.calandar.formulaire.channel")
              }}</label>
              <div class="col-md-12">
                <select class="form-control" v-model="channelId">
                  <option
                    :value="channel.id"
                    v-for="channel in channels"
                    :key="channel.id"
                  >
                    {{ channel.channel_name }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-lg-6">
              <label class="col-form-label">{{
                $t("pages.calandar.formulaire.programme")
              }}</label>
              <div class="col-md-12">
                <select
                  class="form-control"
                  v-model="idProgramme"
                  @change="getDuree(idProgramme)"
                >
                  <option
                    :value="programme.id"
                    v-for="programme in programmes"
                    :key="programme.id"
                  >
                    {{ programme.nom }}
                  </option>
                </select>
              </div>
            </div>
          </div>

          <div class="row mb3">
            <div class="col-lg-6">
              <div class="form-group">
                <label for="projectname" class="col-form-label">{{
                  $t("pages.calandar.formulaire.date")
                }}</label>
                <div class="col-lg-12">
                  <input
                    id="projectname"
                    name="projectname"
                    type="date"
                    class="form-control"
                    v-model="startDate"
                  />
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="form-group">
                <label for="projectname" class="col-form-label">{{
                  $t("pages.calandar.formulaire.time")
                }}</label>
                <div class="col-lg-12">
                  <input
                    id="projectname"
                    name="projectname"
                    type="time"
                    class="form-control"
                    v-model="startTime"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="form-group row mb-3">
            <label for="projectdesc" class="col-form-label">{{
              $t("pages.calandar.formulaire.description.label")
            }}</label>
            <div class="col-lg-12">
              <textarea
                id="projectdesc"
                class="form-control"
                rows="3"
                :placeholder="
                  $t('pages.calandar.formulaire.description.placeholder')
                "
                v-model="description"
              ></textarea>
            </div>
          </div>
        </form>
        <div class="row justify-content-end">
          <div class="col-lg-10">
            <button
              type="submit"
              class="btn c2play-primary"
              @click="createPlanning"
            >
              {{ $t("addButton") }}
            </button>
          </div>
        </div>
      </div>
      <!-- </div> -->
    </b-modal>

    <b-modal
      v-model="cascadeModalOk"
      id="modal-xl"
      size="xl"
      :title="$t('pages.calandar.formulaire.title')"
      title-class="font-18"
      hide-footer
    >
      <!-- <div class="card" style="padding: 25px; width: 70%; margin-left: auto; margin-right: auto; border-radius: 15px;"> -->

      <!-- <div class="card" style="padding: 25px; width: 65%; margin-left: auto; margin-right: auto; border-radius: 15px;"> -->
      <div class="card-body" v-if="plannif == 'onePlaning'">
        <form>
          <div class="row">
            <div class="col-lg-6">
              <label class="col-form-label">{{
                $t("pages.calandar.formulaire.channel")
              }}</label>
              <div class="col-md-12">
                <select class="form-control" v-model="channelId">
                  <option
                    :value="channel.id"
                    v-for="channel in channels"
                    :key="channel.id"
                  >
                    {{ channel.channel_name }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-lg-6">
              <label class="col-form-label">{{
                $t("pages.calandar.formulaire.programme")
              }}</label>
              <div class="col-md-12">
                <select
                  class="form-control"
                  v-model="idProgramme"
                  @change="getDuree(idProgramme)"
                >
                  <option
                    :value="programme.id"
                    v-for="programme in programmes"
                    :key="programme.id"
                  >
                    {{ programme.nom }}
                  </option>
                </select>
              </div>
            </div>
          </div>

          <div class="row mb3">
            <div class="col-lg-4">
              <div class="form-group">
                <label for="projectname" class="col-form-label">{{
                  $t("pages.calandar.formulaire.dateD")
                }}</label>
                <div class="col-lg-12">
                  <input
                    id="projectname"
                    name="projectname"
                    type="date"
                    class="form-control"
                    v-model="startDate"
                  />
                </div>
              </div>
            </div>

            <div class="col-lg-4">
              <div class="form-group">
                <label for="projectname" class="col-form-label">{{
                  $t("pages.calandar.formulaire.dateF")
                }}</label>
                <div class="col-lg-12">
                  <input
                    id="projectname"
                    name="projectname"
                    type="date"
                    class="form-control"
                    v-model="endDate"
                  />
                </div>
              </div>
            </div>

            <div class="col-lg-4">
              <div class="form-group">
                <label for="projectname" class="col-form-label">{{
                  $t("pages.calandar.formulaire.time")
                }}</label>
                <div class="col-lg-12">
                  <input
                    id="projectname"
                    name="projectname"
                    type="time"
                    class="form-control"
                    v-model="startTime"
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="form-group row mb-3">
            <label for="projectdesc" class="col-form-label">
              {{ $t("pages.calandar.formulaire.day") }}
            </label>

            <div style="display: flex; justify-content: space-between">
              <div>
                <b-form-checkbox
                  id="checkbox-1"
                  v-model="joursSemaine"
                  name="checkbox-1"
                  value="Lundi"
                  unchecked-value="not_accepted"
                >
                  {{ $t("pages.calandar.weeks.mon") }}
                </b-form-checkbox>
              </div>

              <div>
                <b-form-checkbox
                  id="checkbox-2"
                  v-model="joursSemaine"
                  name="checkbox-1"
                  value="Mardi"
                  unchecked-value="not_accepted"
                >
                  {{ $t("pages.calandar.weeks.tues") }}
                </b-form-checkbox>
              </div>

              <div>
                <b-form-checkbox
                  id="checkbox-3"
                  v-model="joursSemaine"
                  name="checkbox-1"
                  value="Mercredi"
                  unchecked-value="not_accepted"
                >
                  {{ $t("pages.calandar.weeks.wen") }}
                </b-form-checkbox>
              </div>

              <div>
                <b-form-checkbox
                  id="checkbox-4"
                  v-model="joursSemaine"
                  name="checkbox-1"
                  value="Jeudi"
                  unchecked-value="not_accepted"
                >
                  {{ $t("pages.calandar.weeks.thur") }}
                </b-form-checkbox>
              </div>

              <div>
                <b-form-checkbox
                  id="checkbox-5"
                  v-model="joursSemaine"
                  name="checkbox-1"
                  value="Vendredi"
                  unchecked-value="not_accepted"
                >
                  {{ $t("pages.calandar.weeks.fri") }}
                </b-form-checkbox>
              </div>

              <div>
                <b-form-checkbox
                  id="checkbox-6"
                  v-model="joursSemaine"
                  name="checkbox-1"
                  value="Samedi"
                  unchecked-value="not_accepted"
                >
                  {{ $t("pages.calandar.weeks.sat") }}
                </b-form-checkbox>
              </div>

              <div>
                <b-form-checkbox
                  id="checkbox-7"
                  v-model="joursSemaine"
                  name="checkbox-1"
                  value="Dimanche"
                  unchecked-value="not_accepted"
                >
                  {{ $t("pages.calandar.weeks.sun") }}
                </b-form-checkbox>
              </div>
            </div>
          </div>

          <div class="form-group row mb-3">
            <label for="projectdesc" class="col-form-label">{{
              $t("pages.calandar.formulaire.description.label")
            }}</label>
            <div class="col-lg-12">
              <textarea
                id="projectdesc"
                class="form-control"
                rows="3"
                :placeholder="
                  $t('pages.calandar.formulaire.description.placeholder')
                "
                v-model="description"
              ></textarea>
            </div>
          </div>
        </form>
        <div class="row justify-content-end">
          <div class="col-lg-10">
            <button
              type="submit"
              class="btn c2play-primary"
              style=""
              @click="cascadeRequest"
            >
              {{ $t("addButton") }}
            </button>
          </div>
        </div>
      </div>
      <!-- </div> -->
    </b-modal>
  </Layout>
</template>
